/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FrbConstants } from '@ic-app/constants/frb.constants';
import { AppConfigService } from '@ic-app/services/app-config.service';
import { DialogPDFComponent } from '@ic-components/form/dialog-pdf/dialog-pdf.component';
import { getDateFromTimeStampInLocaleFormat } from '@ic-core/util/global-util';
import { getTimestampFromDate } from '@ic-core/util/request-util';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import {
  AdministrativeFileFilter,
  IAdministrativeFileFilter
} from '@ic-models/prior-inspection/administrative-file-filter.model';
import { AdministrativeFileService } from '@ic-services/administrative-file.service';
import { NavService } from '@ic-services/nav.service';
import { PDFService } from '@ic-services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
// import moment, { MomentInput } from 'moment';
import {
  catchError,
  EMPTY,
  finalize,
  Observable,
  Subject,
  takeUntil,
  throwError
} from 'rxjs';

@Component({
  selector: 'ic-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  reportsForm: FormGroup;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: any;
  fiscalYear?: IFiscalYear;
  initYear?: Date;
  endYear?: Date;
  generateReportClicked = false;
  isFormEmpty = true;
  administrativeFileFilter?: IAdministrativeFileFilter;
  userRoles: string[] = [];

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: FormBuilder,
    private pdfService: PDFService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private administrativeFileService: AdministrativeFileService,
    private appConfigService: AppConfigService,
    @Inject(LOCALE_ID) private readonly localeId: string
  ) {
    this.reportsForm = this.crearFormulario();
    this.initControlsObject();
  }

  ngOnInit(): void {
    this.administrativeFileFilter = new AdministrativeFileFilter();
    const mainTitle = this.translate.instant(
      'sidebar.menu.label.prior-inspection.title'
    ) as string;
    const title = (mainTitle + ' - ').concat(
      this.translate.instant(
        this.route.snapshot.data['title'] as string
      ) as string
    );
    this.navService.updateTitle(title);
    this.reportsForm.valueChanges.subscribe(() => {
      this.isFormEmpty = this.isFormEmptyFn();
    });

    this.userRoles = this.appConfigService.authorities;
  }

  crearFormulario(): FormGroup {
    return this.fb.group({
      dateStartInput: new FormControl(),
      dateEndInput: new FormControl()
    });
  }

  initControlsObject(): void {
    this.controls = {
      dateStart: this.reportsForm?.get('dateStartInput'),
      dateEnd: this.reportsForm?.get('dateEndInput')
    };
  }

  submitDateRange(): void {
    if (
      !this.generateReportClicked &&
      (this.controls?.dateStart?.value || !this.controls?.dateEnd?.value)
    ) {
      this.generateReportClicked = true;

      // TODO: Sustituimos moment
      // Convertimos las fechas a formato numérico
      // const startDateMoment = moment(
      //   this.controls?.dateStart?.value as MomentInput,
      //   'DD-MM-YYYY'
      // );
      // const startDate = getTimestampFromMoment(startDateMoment);
      // const endDateMoment = moment(
      //   this.controls?.dateEnd?.value as MomentInput,
      //   'DD-MM-YYYY'
      // );
      // const endDate = getTimestampFromMoment(endDateMoment);

      // const startDateString = startDateMoment.local().format('DD-MM-YYYY');
      // const endDateString = endDateMoment.local().format('DD-MM-YYYY');

      const startDate = getTimestampFromDate(
        this.controls?.dateStart?.value as Date
      );

      const endDate = getTimestampFromDate(
        this.controls?.dateEnd?.value as Date
      );

      const startDateString = getDateFromTimeStampInLocaleFormat(
        startDate,
        this.localeId
      );
      const endDateString = getDateFromTimeStampInLocaleFormat(
        endDate,
        this.localeId
      );

      this.pdfService
        .generateStatisticsReport(
          startDate,
          endDate,
          startDateString,
          endDateString
        )
        .pipe(
          takeUntil(this.unsubscribe$),
          catchError((error: Error) => {
            this.showMessage(
              this.translate.instant(
                'reports.generate-statistics-report.error'
              ) as string,
              this.translate.instant('common.button.accept') as string
            );
            this.generateReportClicked = false;
            return throwError(() => new Error(error.message));
          }),
          finalize(() => {
            this.generateReportClicked = false;
          })
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .subscribe((response: any) => {
          const file = new Blob([response], {
            type: 'application/pdf'
          });
          const fileURL = window.URL.createObjectURL(file);
          this.openModal(fileURL);
        });
    }
  }

  isFormEmptyFn(): boolean {
    if (
      this.reportsForm?.get('dateStartInput')?.value !== undefined &&
      this.reportsForm?.get('dateStartInput')?.value !== null &&
      this.reportsForm?.get('dateStartInput')?.value !== '' &&
      this.reportsForm?.get('dateEndInput')?.value !== undefined &&
      this.reportsForm?.get('dateEndInput')?.value !== null &&
      this.reportsForm?.get('dateEndInput')?.value !== ''
    ) {
      return false;
    }
    return true;
  }

  // calculateDates() {
  //   let actualDate = new Date();
  //   if (this.fiscalYear.description === actualDate.getFullYear()) {
  //     // Utilizamos el inicio y fin de la semana
  //     let initDateMoment = moment(actualDate, 'DD-MM-YYYY').startOf('isoWeek');
  //     let initDate = getTimestampFromMoment(initDateMoment);
  //     this.controls.dateStart.setValue(new Date(initDate));

  //     // Utilizamos el inicio y fin de la semana
  //     let endDateMoment = moment(actualDate, 'DD-MM-YYYY').endOf('isoWeek');
  //     let endDate = getTimestampFromMoment(endDateMoment);
  //     this.controls.dateEnd.setValue(new Date(endDate));
  //   } else {
  //     this.controls.dateEnd.setValue('');
  //     this.controls.dateStart.setValue('');
  //   }
  // }

  // calculateLimitDates() {
  //   this.appConfigService.selectedFiscalYear$
  //     .subscribe((fiscalYear: IFiscalYear) => {
  //       if (fiscalYear !== null) {
  //         this.fiscalYear = fiscalYear;
  //         let yearSelected = this.fiscalYear.description;
  //         let iYear = moment({ year: yearSelected, month: 0, day: 1 });
  //         let eYear = moment({
  //           year: yearSelected,
  //           month: 11,
  //           day: 31,
  //         });
  //         this.initYear = new Date(getTimestampFromMoment(iYear));
  //         this.endYear = new Date(getTimestampFromMoment(eYear));
  //         this.calculateDates();
  //       }
  //     });
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModal(fileUrl: any): void {
    this.dialog
      .open(DialogPDFComponent, {
        data: {
          title: this.translate.instant('reports.title') as string,
          file: this.sanitizer.bypassSecurityTrustResourceUrl(
            fileUrl as string
          ),
          buttonCancel: this.translate.instant(
            'reports.generate-statistics-report.cancel'
          ) as string
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {});
  }

  showMessage(text: string, button: string): void {
    this._snackBar.open(text, button, {
      duration: 5000
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  downloadReportAdministrativeFiles($event: Event): void {
    $event.preventDefault();

    this.administrativeFileService
      .downloadAdministrativeFiles(
        this.administrativeFileFilter as IAdministrativeFileFilter,
        FrbConstants.INSPECTION_ADMINISTRATIVE_FILE_CODE
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((_error: Error) =>
          this.showSubscribeGetterError(
            'reports.error-messages.download-report-administrative-files'
          )
        )
      )
      .subscribe((res: string) => {
        this.showMessage(
          this.translate.instant(
            'reports.success-messages.download-report-administrative-files'
          ) as string,
          this.translate.instant('common.button.accept') as string
        );
        window.location.assign(res);
      });
  }

  downloadReportIterationAdministrativeFiles($event: Event): void {
    $event.preventDefault();

    this.administrativeFileService
      .downloadAdministrativeFiles(
        this.administrativeFileFilter as IAdministrativeFileFilter,
        FrbConstants.INSPECTION_PROCEDURE_ITERATION_CODE
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((_error: Error) =>
          this.showSubscribeGetterError(
            'reports.error-messages.download-iteration-files'
          )
        )
      )
      .subscribe((res: string) => {
        this.showMessage(
          this.translate.instant(
            'reports.success-messages.download-iteration-files'
          ) as string,
          this.translate.instant('common.button.accept') as string
        );
        window.location.assign(res);
      });
  }

  downloadReportAdministrativeFilesObservations($event: Event): void {
    $event.preventDefault();

    this.administrativeFileService
      .downloadAdministrativeFiles(
        this.administrativeFileFilter as IAdministrativeFileFilter,
        FrbConstants.INSPECTION_OBSERVATION_CODE
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((_error: Error) =>
          this.showSubscribeGetterError(
            'reports.error-messages.download-report-observations-files'
          )
        )
      )
      .subscribe((res: string) => {
        this.showMessage(
          this.translate.instant(
            'reports.success-messages.download-report-observations-files'
          ) as string,
          this.translate.instant('common.button.accept') as string
        );
        window.location.assign(res);
      });
  }

  private showSubscribeGetterError(message: string): Observable<never> {
    this.showMessage(
      this.translate.instant(message) as string,
      this.translate.instant('common.button.accept') as string
    );
    return EMPTY;
  }
}
