import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { getUserTimeZone } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampAtStartOfDay,
  toCurrencyNumber
} from '@ic-core/util/request-util';
import { IOmissionAdded } from '@ic-models/omission/omission-request.model';
import { IAdministrativeFileFilter } from '@ic-models/prior-inspection/administrative-file-filter.model';
import {
  AdministrativeFile,
  IAdministrativeFileAdded
} from '@ic-models/prior-inspection/administrative-file.model';
import { IDetailData } from '@ic-models/prior-inspection/detail.model';
import {
  FrbCorrectionRequest,
  ICorrectionOSData,
  IFrbAdded,
  IFrbCorrectionData,
  IFrbCorrectionDataDTO,
  IFrbCorrectionDataToEdit,
  IFrbCorrectionDataType,
  IFrbRequest,
  IFrbRequestCorrectionContacts,
  InspectionRequest
} from '@ic-models/prior-inspection/frb-request.model';
import { IProcedureDetail } from '@ic-models/procedure-detail.model';
import { ISpendingPhaseOption } from '@ic-models/spendingPhase.model';
import { IStatusType } from '@ic-models/statusType.model';
import { IDocumentsForm } from '@ic-models/storage/document-data';
import { environment } from 'environments/environment';
// import * as _moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;
// const moment = _moment;

@Injectable()
export class FrbService {
  constructor(private http: HttpClient) {}

  findFrbRequest(
    filter: IAdministrativeFileFilter,
    pageNumber: number,
    pageSize: number
  ): Observable<IFrbRequest[]> {
    // let offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      searchBox: filter.searchBox,
      importMinInput: filter.importMin
        ? toCurrencyNumber(filter.importMin)
        : null,
      importMaxInput: filter.importMax
        ? toCurrencyNumber(filter.importMax)
        : null,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      administrativeFileType: filter.administrativeFileType?.id as number,
      procedureResultCodes: filter.procedureResultCodes,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      spendingPhaseId: filter.spendingPhaseId,
      omissionReasonId: filter.omissionReasonId,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      budgetaryItem: filter.budgetaryItem?.id as number,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbRequest[]>(`${baseUrl}/inspectionrequest/list`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IFrbRequest[]>) => resp.body as IFrbRequest[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las solicitudes de Fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findProceduresByAdministrativeFileId(
    fileId: number
  ): Observable<IProcedureDetail[]> {
    const req = {
      id: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IProcedureDetail[]>(
        `${baseUrl}/inspectionrequest/proceduresByAdministrativeFileId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IProcedureDetail[]>) =>
            resp.body as IProcedureDetail[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los trámites en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findProcedureByProcedureId(
    procedureId: number
  ): Observable<IProcedureDetail> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IProcedureDetail>(
        `${baseUrl}/inspectionrequest/procedureByProcedureId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IProcedureDetail>) =>
            resp.body as IProcedureDetail
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los trámites en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteFrbRequest(fileId: number): Observable<boolean> {
    const req = {
      administrativeFileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/inspectionrequest/delete`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de Fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFrbRequest(filter: IAdministrativeFileFilter): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      importMinInput: filter.importMin
        ? toCurrencyNumber(filter.importMin)
        : null,
      importMaxInput: filter.importMax
        ? toCurrencyNumber(filter.importMax)
        : null,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      administrativeFileType: filter.administrativeFileType?.id as number,
      fileNumber: filter.fileNumber,
      spendingPhaseId: filter.spendingPhaseId,
      omissionReasonId: filter.omissionReasonId,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      budgetaryItem: filter.budgetaryItem?.id as number,
      procedureResultCodes: filter.procedureResultCodes
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/count', {
        .get<number>(`${baseUrl}/inspectionrequest/countrequests`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map((resp: HttpResponse<number>) => resp.body as number),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al contar las solicitudes de fiscalización del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  saveFrbRequest(inspectionRequest: InspectionRequest): Observable<IFrbAdded> {
    // let year = this.appConfig.getSelectedFiscalYear();
    // console.log("prueba year")
    const req = {
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .post<IFrbAdded>(`${baseUrl}/inspectionrequest`, inspectionRequest, {
        params: options
      })
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveFrbRequestAndValidate(
    inspectionRequest: InspectionRequest
  ): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };

    const options = createRequestOption(req);

    return this.http
      .post<IFrbAdded>(
        `${baseUrl}/inspectionrequest/validateAndAccept`,
        inspectionRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y validar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  uploadFilesData(
    filesData: IDocumentsForm,
    fileId: number,
    validate: boolean
  ): Observable<IFrbAdded | IOmissionAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      fileId: fileId,
      validate: validate,
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<IFrbAdded | IOmissionAdded>(
        `${baseUrl}/inspectionrequest/uploadFilesData`,
        filesData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded | IOmissionAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la información de los archivos en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateFrbRequest(
    inspectionRequest: InspectionRequest,
    procedureId: number
  ): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // let initDay = moment().startOf('day').valueOf();
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      // initDay: initDay,
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .put<IFrbAdded>(
        `${baseUrl}/inspectionrequest/update`,
        inspectionRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateAdministrativeFile(
    administrativeFile: AdministrativeFile,
    fileId: number,
    deletePronouncements: boolean
  ): Observable<IAdministrativeFileAdded> {
    const req = {
      fileId: fileId,
      deletePronouncements: deletePronouncements,
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .put<IAdministrativeFileAdded>(
        `${baseUrl}/administrativefile/update`,
        administrativeFile,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IAdministrativeFileAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateAdministrativeFileDocuments(
    administrativeFile: AdministrativeFile,
    fileId: number
  ): Observable<boolean> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .put<boolean>(
        `${baseUrl}/administrativefile/updateDocuments`,
        administrativeFile,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateFrbRequestDocuments(
    inspectionRequest: InspectionRequest,
    procedureId: number
  ): Observable<boolean> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .put<boolean>(
        `${baseUrl}/inspectionrequest/updateDocuments`,
        inspectionRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteUrgentCondition(administrativeFileId: number): Observable<boolean> {
    const req = {
      urgent: false,
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/administrativefile/updateurgent`, {
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateFrbRequestAndAccept(
    inspectionRequest: InspectionRequest,
    procedureId: number
  ): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .put<IFrbAdded>(
        `${baseUrl}/inspectionrequest/updateAndAccept`,
        inspectionRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y aceptar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  acceptFrbRequest(procedureId: number): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbAdded>(`${baseUrl}/inspectionrequest/accept`, {
        params: options
      })
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al aceptar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionData(procedureId: number): Observable<IFrbCorrectionData> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbCorrectionData>(`${baseUrl}/inspectionrequest/correctionData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFrbCorrectionData>) =>
            resp.body as IFrbCorrectionData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las información para crear una subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionToEdit(
    procedureId: number
  ): Observable<IFrbCorrectionDataToEdit> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbCorrectionDataToEdit>(
        `${baseUrl}/inspectionrequest/correctionDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IFrbCorrectionDataToEdit>) =>
            resp.body as IFrbCorrectionDataToEdit
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionReasonsData(
    typeId: number,
    initDate: string,
    ouFileNumber: string,
    templateUri: string,
    offset: number,
    subject?: string
  ): Observable<IFrbCorrectionDataDTO> {
    // TODO: actualizamos el valor initDateMillis a partir de un initDate string from Java Instant
    // const initDateMillis = new Date(Date.parse(initDate)).getTime();

    const req = {
      typeId: typeId,
      // initDate: initDateMillis,
      initDate: initDate, // Java Instant va como un String con formato Instant: '2023-04-17T10:03:01.567486Z'
      ouFileNumber: ouFileNumber,
      templateUri: templateUri,
      zoneId: getUserTimeZone(),
      // offset: offset,
      subject: subject
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbCorrectionDataDTO>(
        `${baseUrl}/inspectionrequest/correctionReasonsData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IFrbCorrectionDataDTO>) =>
            resp.body as IFrbCorrectionDataDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los motivos de una subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionTypesData(): Observable<IFrbCorrectionDataType[]> {
    const options = createRequestOption();
    return this.http
      .get<IFrbCorrectionDataType[]>(
        `${baseUrl}/inspectionrequest/correctionTypesData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IFrbCorrectionDataType[]>) =>
            resp.body as IFrbCorrectionDataType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de una subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveCorrection(correctionData: FrbCorrectionRequest): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/inspectionrequest/saveCorrection`,
        correctionData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editCorrection(
    correctionData: FrbCorrectionRequest,
    officialNoteId: number
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionrequest/editCorrection`,
        correctionData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  sendCorrection(
    procedureId: number,
    contactsToSendEmails: string[],
    contactIdsToSend: number[],
    fileNumber: string,
    send: boolean = true
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      contactsToSendEmails: contactsToSendEmails,
      contactIdsToSend: contactIdsToSend,
      fileNumber: fileNumber,
      zoneId: zoneId,
      // offset: offset,
      send: send
    };

    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/inspectionrequest/sendCorrection`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar una solicitud de subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveCorrectionAndSend(
    correctionData: FrbCorrectionRequest,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      zoneId: getUserTimeZone(),
      // offset: moment().utcOffset(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionrequest/saveCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editCorrectionAndSend(
    correctionData: FrbCorrectionRequest,
    officialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId,
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      zoneId: getUserTimeZone(),
      // offset: moment().utcOffset(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionrequest/editCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getStatusTypes(): Observable<IStatusType[]> {
    const options = createRequestOption();
    return this.http
      .get<IStatusType[]>(`${baseUrl}/inspectionrequest/status`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IStatusType[]>) => resp.body as IStatusType[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de estado:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getSupportedSpendingPhases(): Observable<ISpendingPhaseOption[]> {
    const options = createRequestOption();
    return this.http
      .get<ISpendingPhaseOption[]>(
        `${baseUrl}/inspectionrequest/spendingPhase`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISpendingPhaseOption[]>) =>
            resp.body as ISpendingPhaseOption[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las fases de gasto:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionContacts(
    organizationalUnitId: number
  ): Observable<IFrbRequestCorrectionContacts[]> {
    const req = {
      organizationalUnitId: organizationalUnitId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFrbRequestCorrectionContacts[]>(
        `${baseUrl}/inspectionrequest/organizationalUnitContacts`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IFrbRequestCorrectionContacts[]>) =>
            resp.body as IFrbRequestCorrectionContacts[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los contactos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteFrbCorrectionRequest(procedureId: number): Observable<boolean> {
    const req = {
      procedureId: procedureId,
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/inspectionrequest/deleteCorrectionRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de subsanación del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getAmendmentData(
    procedureId: number,
    procedureTypeCode: number
  ): Observable<ICorrectionOSData> {
    const req = {
      procedureId: procedureId,
      procedureTypeCode: procedureTypeCode
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICorrectionOSData>(`${baseUrl}/inspectionrequest/amendmentData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ICorrectionOSData>) =>
            resp.body as ICorrectionOSData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos de la solicitud a subsanar del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  createAmendmentRequest(
    amendment: InspectionRequest,
    procedureId: number
  ): Observable<IFrbAdded> {
    const req = {
      procedureId: procedureId,
      zoneId: getUserTimeZone()
    };

    const options = createRequestOption(req);

    return this.http
      .post<IFrbAdded>(
        `${baseUrl}/inspectionrequest/saveAmendment`,
        amendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findFrbRequestDetail(procedureId: number): Observable<IDetailData> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IDetailData>(`${baseUrl}/inspectionrequest/frbDetail`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IDetailData>) => resp.body as IDetailData),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar un expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  createAmendmentRequestAndValidate(
    amendment: InspectionRequest,
    procedureId: number
  ): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();

    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId,
      // offset: offset,
      procedureId: procedureId
    };

    const options = createRequestOption(req);

    return this.http
      .post<IFrbAdded>(
        `${baseUrl}/inspectionrequest/saveAmendmentAndValidate`,
        amendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y validar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de subsanación de solicitud a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithCorectionReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/inspectionrequest/sendEmail/correctionReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
