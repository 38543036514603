import { Sort } from '@angular/material/sort';
import { AutoCompleteElement } from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import {
  getTimestampAtEndOfDayFromDate,
  getTimestampAtStartOfDayFromDate,
  isDateType
} from '@ic-core/util/request-util';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import * as moment from 'moment';

// eslint-disable-next-line no-duplicate-imports

export interface IAdministrativeFileFilter {
  searchBox?: string;
  importMin?: string;
  importMax?: string;
  initDateStart?: number;
  initDateEnd?: number;
  initDateStartString?: string;
  initDateEndString?: string;
  endDateStartString?: string;
  endDateEndString?: string;
  endDateStart?: number;
  endDateEnd?: number;
  statusType?: number;
  procedureStatusType?: number;
  administrativeFileStatus?: IAdministrativeFileStatus[];
  organizationalUnit?: AutoCompleteElement | undefined;
  organizationalUnitResponsible?: AutoCompleteElement | undefined;
  administrativeFileType?: AutoCompleteElement | undefined;
  spendingPhase?: string;
  omissionReason?: string;
  budgetaryItem?: AutoCompleteElement | undefined;
  frbStatusFilter: boolean;
  fileNumber?: string;
  procedureResultCodes?: number[];
  spendingPhaseId?: number;
  omissionReasonId?: number;
  sortActive?: string;
  sortDirection?: string;
  // pageIndex?: number;
}

export interface IAdministrativeFileFilterForm {
  searchboxInput?: string;
  importMinInput?: string;
  importMaxInput?: string;
  initDateStartInput?: Date | string;
  initDateEndInput?: Date | string;
  endDateStartInput?: Date | string;
  endDateEndInput?: Date | string;
  statusTypeInput?: number;
  procedureStatusTypeInput?: number;
  administrativeFileStatusInput?: IAdministrativeFileStatus[];
  administrativeFileTypeInput?: AutoCompleteElement;
  organizationalUnitResponsibleInput?: AutoCompleteElement;
  spendingPhaseInput?: string;
  omissionReasonInput?: string;
  budgetaryItemInput?: AutoCompleteElement;
  organizationalUnitInput?: AutoCompleteElement;
  fileNumberInput?: string;
  frbStatusFilterInput?: boolean;
  procedureResultCodesInput?: number[];
  spendingPhaseIdInput?: number;
  omissionReasonIdInput?: number;
  sortInput: Sort;
}

export interface IAdministrativeFileStatus {
  phaseCode: number;
  resultCode?: number;
}

export class AdministrativeFileFilter implements IAdministrativeFileFilter {
  searchBox: string;
  importMin: string;
  importMax: string;
  initDateStart?: number;
  initDateEnd?: number;
  initDateStartString: string;
  initDateEndString: string;
  endDateStartString: string;
  endDateEndString: string;
  endDateStart?: number;
  endDateEnd?: number;
  sortActive: string;
  sortDirection: string;
  statusType: number;
  administrativeFileType: AutoCompleteElement | undefined;
  spendingPhase: string;
  omissionReason: string;
  budgetaryItem: AutoCompleteElement | undefined;
  organizationalUnitResponsible: AutoCompleteElement | undefined;
  organizationalUnit: AutoCompleteElement | undefined;
  procedureStatusType: number;
  administrativeFileStatus?: IAdministrativeFileStatus[];
  fileNumber: string;
  frbStatusFilter: boolean;
  procedureResultCodes?: number[];
  spendingPhaseId: number;
  omissionReasonId: number;

  constructor(public administrativeFileForm?: IAdministrativeFileFilterForm) {
    this.searchBox = administrativeFileForm?.searchboxInput as string;
    this.importMin = administrativeFileForm?.importMinInput as string;
    this.importMax = administrativeFileForm?.importMaxInput as string;
    //TODO: Ojo con esta conversión, comprobar como llega al backend MOMENT
    this.initDateStartString = administrativeFileForm?.initDateStartInput
      ? isDateType(administrativeFileForm?.initDateStartInput)
        ? (administrativeFileForm?.initDateStartInput as Date).toISOString()
        : (administrativeFileForm?.initDateStartInput as string)
      : '';
    this.initDateEndString = administrativeFileForm?.initDateEndInput
      ? isDateType(administrativeFileForm?.initDateEndInput)
        ? (administrativeFileForm?.initDateEndInput as Date).toISOString()
        : (administrativeFileForm?.initDateEndInput as string)
      : '';
    this.endDateStartString = administrativeFileForm?.endDateStartInput
      ? isDateType(administrativeFileForm?.endDateStartInput)
        ? (administrativeFileForm?.endDateStartInput as Date).toISOString()
        : (administrativeFileForm?.endDateStartInput as string)
      : '';
    this.endDateEndString = administrativeFileForm?.endDateEndInput
      ? isDateType(administrativeFileForm?.endDateEndInput)
        ? (administrativeFileForm?.endDateEndInput as Date).toISOString()
        : (administrativeFileForm?.endDateEndInput as string)
      : '';
    this.statusType = administrativeFileForm?.statusTypeInput as number;
    this.procedureStatusType =
      administrativeFileForm?.procedureStatusTypeInput as number;
    this.organizationalUnit = administrativeFileForm?.organizationalUnitInput;
    this.administrativeFileType =
      administrativeFileForm?.administrativeFileTypeInput;
    this.spendingPhase = administrativeFileForm?.spendingPhaseInput as string;
    this.omissionReason = administrativeFileForm?.omissionReasonInput as string;
    this.budgetaryItem = administrativeFileForm?.budgetaryItemInput;
    this.organizationalUnitResponsible =
      administrativeFileForm?.organizationalUnitResponsibleInput;
    this.administrativeFileStatus =
      administrativeFileForm?.administrativeFileStatusInput || undefined;
    this.frbStatusFilter =
      administrativeFileForm?.frbStatusFilterInput as boolean;
    this.fileNumber = administrativeFileForm?.fileNumberInput as string;
    this.procedureResultCodes =
      administrativeFileForm?.procedureResultCodesInput || undefined;
    this.spendingPhaseId =
      administrativeFileForm?.spendingPhaseIdInput as number;
    this.omissionReasonId =
      administrativeFileForm?.omissionReasonIdInput as number;
    this.sortActive = administrativeFileForm?.sortInput?.active as string;
    this.sortDirection = administrativeFileForm?.sortInput?.direction as string;

    // TODO: Sustituimos moment
    // const initDateStartMoment = moment(
    //   administartiveFileForm?.initDateStartInput,
    //   'DD-MM-YYYY'
    // );
    // const initDateEndMoment = moment(
    //   administartiveFileForm?.initDateEndInput,
    //   'DD-MM-YYYY'
    // );

    // const endDateStartMoment = moment(
    //   administartiveFileForm?.endDateStartInput,
    //   'DD-MM-YYYY'
    // );

    // const endDateEndMoment = moment(
    //   administartiveFileForm?.endDateEndInput,
    //   'DD-MM-YYYY'
    // );

    // this.initDateStart =
    //   getTimestampFromMoment(initDateStartMoment.startOf('day')) as number;

    // this.initDateEnd =
    //   this.calulateEndDate(initDateEndMoment.endOf('day')) as number;

    // this.endDateStart =
    //   getTimestampFromMoment(endDateStartMoment.startOf('day')) as number;

    // this.endDateEnd = this.calulateEndDate(endDateEndMoment.endOf('day')) as number;

    this.initDateStart = administrativeFileForm?.initDateStartInput
      ? isDateType(administrativeFileForm?.initDateStartInput)
        ? getTimestampAtStartOfDayFromDate(
            administrativeFileForm?.initDateStartInput as Date
          )
        : getTimestampAtStartOfDayFromDate(
            new Date(administrativeFileForm?.initDateStartInput as string)
          )
      : undefined;

    this.initDateEnd = administrativeFileForm?.initDateEndInput
      ? isDateType(administrativeFileForm?.initDateEndInput)
        ? getTimestampAtEndOfDayFromDate(
            administrativeFileForm?.initDateEndInput as Date
          )
        : getTimestampAtEndOfDayFromDate(
            new Date(administrativeFileForm?.initDateEndInput as string)
          )
      : undefined;

    this.endDateStart = administrativeFileForm?.endDateStartInput
      ? isDateType(administrativeFileForm?.endDateStartInput)
        ? getTimestampAtStartOfDayFromDate(
            administrativeFileForm?.endDateStartInput as Date
          )
        : getTimestampAtStartOfDayFromDate(
            new Date(administrativeFileForm?.endDateStartInput as string)
          )
      : undefined;

    this.endDateEnd = administrativeFileForm?.endDateEndInput
      ? isDateType(administrativeFileForm?.endDateEndInput)
        ? getTimestampAtEndOfDayFromDate(
            administrativeFileForm?.endDateEndInput as Date
          )
        : getTimestampAtEndOfDayFromDate(
            new Date(administrativeFileForm?.endDateEndInput as string)
          )
      : undefined;
  }

  // Se restan los últimos milisegundos del día siguiente al límite
  // calulateEndDate(date: moment.Moment) {
  //   const numberDate = getTimestampFromMoment(date);
  //   if (numberDate != null) {
  //     return numberDate - 999;
  //   } else {
  //     return null;
  //   }
  // }
}
