import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import {
  IPpcProcedureResult,
  IPpcReportConclusionStatus
} from '@ic-app/models/prior-operational/ppc-procedure-result.model';
import { getUserTimeZone } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampAtStartOfDay,
  getTimestampAtStartOfDayFromTimestamp
} from '@ic-core/util/request-util';
import { IPpcDetailData } from '@ic-models/prior-control/ppc-detail.model';
import { IPpcAdministrativeFileFilter } from '@ic-models/prior-operational/ppc-administrative-file-filter.model';
import {
  IPpcAdministrativeFileAdded,
  IPpcAdministrativeFileCorrectionContacts,
  IPpcAdministrativeFileCorrectionDataDTO,
  IPpcAdministrativeFileCorrectionDataEditDTO,
  IPpcAdministrativeFileRequest,
  IPpcAdministrativeFileReturnRequestEditDTO,
  PpcAdministrativeFile,
  PpcAdministrativeFileCorrectionRequest,
  PpcAdministrativeFileReturn,
  PpcResponseReport
} from '@ic-models/prior-operational/ppc-administrative-file.model';
import { IPpcAreaType } from '@ic-models/prior-operational/ppc-area-type.model';
import { IPpcReportData } from '@ic-models/prior-operational/ppc-report/ppc-report.model';
import {
  IPpcCorrectionDataType,
  IPpcCorrectionOSData
} from '@ic-models/prior-operational/ppc-request.model';
import { IPpcTopicType } from '@ic-models/prior-operational/ppc-topic-type.model';
import { IStatusType } from '@ic-models/statusType.model';
import { environment } from 'environments/environment';
// import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcAdministrativeFileService {
  constructor(private http: HttpClient) {}

  findPpcAdministrativeFiles(
    filter: IPpcAdministrativeFileFilter,
    pageNumber: number,
    pageSize: number,
    internalPpcAdministrativeFileTypeCode: number
  ): Observable<IPpcAdministrativeFileRequest[]> {
    //TODO zoneid
    // const offset = moment().utcOffset();
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      // offset: offset,
      zoneId: getUserTimeZone(),
      internalPpcAdministrativeFileTypeCode,
      ppcAdministrativeFileStatus:
        !!filter.ppcAdministrativeFileStatus &&
        filter.ppcAdministrativeFileStatus.length > 0
          ? JSON.stringify(filter.ppcAdministrativeFileStatus)
          : undefined,
      ppcAdministrativeFileTopic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      ppcAdministrativeFileArea:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle,
      ppcReportConclusionStatus:
        !!filter.ppcReportConclusionStatus &&
        filter.ppcReportConclusionStatus.length > 0
          ? JSON.stringify(filter.ppcReportConclusionStatus)
          : undefined
    };

    // if (
    //   !!filter.ppcAdministrativeFileStatus &&
    //   filter.ppcAdministrativeFileStatus.length > 0
    // )
    //   req['ppcAdministrativeFileStatus'] = JSON.stringify(
    //     filter.ppcAdministrativeFileStatus
    //   );

    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdministrativeFileRequest[]>(
        `${baseUrl}/ppcadministrativefile/listPpcAdministrativeFiles`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcAdministrativeFileRequest[]>) =>
            resp.body as IPpcAdministrativeFileRequest[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los expedientes CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countPpcAdministrativeFileRequest(
    filter: IPpcAdministrativeFileFilter,
    internalPpcAdministrativeFileTypeCode: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      internalPpcAdministrativeFileTypeCode,
      ppcAdministrativeFileStatus:
        !!filter.ppcAdministrativeFileStatus &&
        filter.ppcAdministrativeFileStatus.length > 0
          ? JSON.stringify(filter.ppcAdministrativeFileStatus)
          : undefined,
      ppcAdministrativeFileTopic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      ppcAdministrativeFileArea:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle,
      ppcReportConclusionStatus:
        !!filter.ppcReportConclusionStatus &&
        filter.ppcReportConclusionStatus.length > 0
          ? JSON.stringify(filter.ppcReportConclusionStatus)
          : undefined
    };

    // if (
    //   !!filter.ppcAdministrativeFileStatus &&
    //   filter.ppcAdministrativeFileStatus.length > 0
    // )
    //   req['administrativeFileStatus'] = JSON.stringify(
    //     filter.ppcAdministrativeFileStatus
    //   );

    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/ppcadministrativefile/countPpcAdministrativeFiles`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los expedientes CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  downloadPpcAdministrativeFiles(
    filter: IPpcAdministrativeFileFilter,
    ppcFileTypeCode: number
  ): Observable<string> {
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      procedureResultCodes: filter.procedureResultCodes,
      typeCode: ppcFileTypeCode,
      ppcAdministrativeFileStatus:
        !!filter.ppcAdministrativeFileStatus &&
        filter.ppcAdministrativeFileStatus.length > 0
          ? JSON.stringify(filter.ppcAdministrativeFileStatus)
          : undefined,
      topic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      area:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle,
      ppcReportConclusionStatus:
        !!filter.ppcReportConclusionStatus &&
        filter.ppcReportConclusionStatus.length > 0
          ? JSON.stringify(filter.ppcReportConclusionStatus)
          : undefined
    };

    // if (
    //   !!filter.administrativeFileStatus &&
    //   filter.administrativeFileStatus.length > 0
    // )
    //   req['administrativeFileStatus'] = JSON.stringify(
    //     filter.administrativeFileStatus
    //   );

    const options = createRequestOption(req);

    return this.http
      .get<string>(
        `${baseUrl}/downloadfiles/excel`,
        //.get<any>(`https://devtower.scipionapp.com/api/downloadfiles/excel`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response',
          responseType: 'text' as 'json'
        }
      )
      .pipe(
        map((resp: HttpResponse<string>) => resp.body as string),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al descargar los datos de los expedientes CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Obtiene los datos del expediente para un informe PPC
  findPpcAdministrativeFileReportPpc(
    ppcAdministrativeFileId: number,
    iteration: number
  ): Observable<IPpcReportData> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcReportData>(`${baseUrl}/ppcadministrativefile/reportppc`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcReportData>) => resp.body as IPpcReportData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar un expediente CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Obtiene los datos del expediente PPC en determinada iteración
  findPpcAdministrativeFileDetail(
    ppcAdministrativeFileId: number,
    iteration: number
  ): Observable<IPpcDetailData> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcDetailData>(`${baseUrl}/ppc-administrativefile/fileDetail`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcDetailData>) => resp.body as IPpcDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar un expediente CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcStatusTypes(): Observable<IStatusType[]> {
    const options = createRequestOption();
    return this.http
      .get<IStatusType[]>(`${baseUrl}/ppcadministrativefile/ppcStatus`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IStatusType[]>) => resp.body as IStatusType[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los estados de un expediente cpp:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportConclusionStatus(
    resultCode: number
  ): Observable<IPpcReportConclusionStatus[]> {
    const req = {
      resultCode: resultCode
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcReportConclusionStatus[]>(
        `${baseUrl}/ppcadministrativefile/ppcReportConclusionStatus`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcReportConclusionStatus[]>) =>
            resp.body as IPpcReportConclusionStatus[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los estados de la conclusion del expediente cpp.',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionContacts(
    organizationalUnitId: number
  ): Observable<IPpcAdministrativeFileCorrectionContacts[]> {
    const req = {
      organizationalUnitId: organizationalUnitId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdministrativeFileCorrectionContacts[]>(
        `${baseUrl}/ppcadministrativefile/ppcOrganizationalUnitContacts`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcAdministrativeFileCorrectionContacts[]>) =>
            resp.body as IPpcAdministrativeFileCorrectionContacts[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los contactos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionData(
    ppcAdministrativeFileId: number
  ): Observable<IPpcAdministrativeFileCorrectionDataDTO> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdministrativeFileCorrectionDataDTO>(
        `${baseUrl}/ppcadministrativefile/ppcCorrectionData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcAdministrativeFileCorrectionDataDTO>) =>
            resp.body as IPpcAdministrativeFileCorrectionDataDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las información para crear una subsanación CPP',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcCorrection(
    ppcCorrectionData: PpcAdministrativeFileCorrectionRequest
  ): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset(),
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/ppcadministrativefile/savePpcCorrection`,
        ppcCorrectionData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcCorrectionAndSend(
    correctionData: PpcAdministrativeFileCorrectionRequest,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/savePpcAdministrativeFileCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionToEdit(
    ppcAdministrativeFileId: number
  ): Observable<IPpcAdministrativeFileCorrectionDataEditDTO> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdministrativeFileCorrectionDataEditDTO>(
        `${baseUrl}/ppcadministrativefile/ppcCorrectionDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcAdministrativeFileCorrectionDataEditDTO>) =>
            resp.body as IPpcAdministrativeFileCorrectionDataEditDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación CPP del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcCorrection(
    ppcCorrectionData: PpcAdministrativeFileCorrectionRequest,
    officialNoteId: number
  ): Observable<number> {
    const req = { officialNoteId: officialNoteId };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/updatePpcAdministrativeFileCorrection`,
        ppcCorrectionData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcCorrectionAndSend(
    ppcCorrectionData: PpcAdministrativeFileCorrectionRequest,
    officialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      //TODO zoneId
      officialNoteId: officialNoteId,
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/editPpcAdministrativeFileCorrectionAndSend`,
        ppcCorrectionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcAdministrativeFileCorrectionRequest(
    ppcAdministrativeFileId: number
  ): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(
        `${baseUrl}/ppcadministrativefile/ppcDeleteCorrectionRequest`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de subsanación CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de solicitud de información a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithPpcCorrectionReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/ppcadministrativefile/sendEmail/ppcCorrectionReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de devolucion de expediente a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithPpcReturnReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/ppcadministrativefile/sendEmail/ppcReturnReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findCurrentPpcAdministrativeFile(
    ppcAdministrativeFileId: number
  ): Observable<IPpcDetailData> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/detail', {
        .get<IPpcDetailData>(
          `${baseUrl}/ppcadministrativefile/findCurrentPpcAdministrativeFile`,
          {
            headers: HttpConstants.GET_HEADERS,
            params: options,
            observe: 'response'
          }
        )
        .pipe(
          map(
            (resp: HttpResponse<IPpcDetailData>) => resp.body as IPpcDetailData
          ),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al cargar la solicitud de Fiscalización del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  savePpcResponseReport(
    ppcResponseReport: PpcResponseReport,
    ppcAdministrativeFileId: number
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    // TODO: Sustituimos moment
    // const initDay = moment(responseReport.responseDate)
    //   .startOf('day')
    //   .valueOf();
    const initDay = getTimestampAtStartOfDayFromTimestamp(
      ppcResponseReport.ppcResponseDate
    );
    const req = {
      zoneId: zoneId,
      // offset: offset,
      initDay: initDay
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/ppcadministrativefile/ppcResponseReport/${ppcAdministrativeFileId}/`,
        ppcResponseReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.log(
            `Error al responser el oficio CPP del expediente ${ppcAdministrativeFileId}`,
            err
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionTypesData(): Observable<IPpcCorrectionDataType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcCorrectionDataType[]>(`${baseUrl}/ppc/ppcCorrectionTypesData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionDataType[]>) =>
            resp.body as IPpcCorrectionDataType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de una subsanación CPP',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcReturnFile(
    returnData: PpcAdministrativeFileReturn
  ): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset(),
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/ppcadministrativefile/savePpcReturn`,
        returnData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcAdministrativeFileReturnRequest(
    ppcAdministrativeFileId: number
  ): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/ppcadministrativefile/deletePpcReturnRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de devolucion CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcReturnAndSend(
    returnData: PpcAdministrativeFileReturn,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/savePpcAdministrativeFileReturnAndSend`,
        returnData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la devolucion del expediente CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReturnDataToEdit(
    ppcAdministrativeFileId: number
  ): Observable<IPpcAdministrativeFileReturnRequestEditDTO> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdministrativeFileReturnRequestEditDTO>(
        `${baseUrl}/ppcadministrativefile/returnPpcDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcAdministrativeFileReturnRequestEditDTO>) =>
            resp.body as IPpcAdministrativeFileReturnRequestEditDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcReturnData(
    returnData: PpcAdministrativeFileReturn,
    ppcOfficialNoteId: number
  ): Observable<number> {
    const req = {
      ppcOfficialNoteId: ppcOfficialNoteId
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/updatePpcAdministrativeFileReturn`,
        returnData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la devolución CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcReturnAndSend(
    returnData: PpcAdministrativeFileReturn,
    ppcOfficialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      //TODO zoneId
      ppcOfficialNoteId: ppcOfficialNoteId,
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcadministrativefile/editPpcAdministrativeFileReturnAndSend`,
        returnData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la devolución CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Recupera el id del ppcProcedure del expediente CPP activa
  getActivePpcReportProcedureIdFromPpcAdministrativeFile(
    ppcAdministrativeFileId: number
  ): Observable<number> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/ppcadministrativefile/activePpcReportProcedureIdFromPpcAdministrativeFile`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al obetener los datos del procedimiento CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReopenData(ppcProcedureId: number): Observable<IPpcCorrectionOSData> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionOSData>(
        `${baseUrl}/ppcadministrativefile/ppcReopenData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionOSData>) =>
            resp.body as IPpcCorrectionOSData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos para reabrir el expediente CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Crea reformulacion o reapertura al expediente
  createReopenOrReformulationPpcAdministrativeFile(
    ppcAmendment: PpcAdministrativeFile,
    ppcProcedureId: number,
    returned: boolean
  ): Observable<IPpcAdministrativeFileAdded> {
    const req = {
      ppcProcedureId: ppcProcedureId,
      returned: returned,
      zoneId: getUserTimeZone()
    };

    const options = createRequestOption(req);

    return this.http
      .post<IPpcAdministrativeFileAdded>(
        `${baseUrl}/ppcadministrativefile/saveReopenOrReformulationPpc`,
        ppcAmendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdministrativeFileAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          if (returned) {
            console.error(
              'Se ha producido un error al guardar la reapertura en el servidor:',
              err.message
            );
          } else {
            console.error(
              'Se ha producido un error al guardar la subsanación en el servidor:',
              err.message
            );
          }
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Crea reformulacion o reapertura al expediente y valida la solicitud
  createReopenOrReformulationPpcAdministrativeFileAndValidate(
    amendment: PpcAdministrativeFile,
    ppcProcedureId: number,
    returned: boolean
  ): Observable<IPpcAdministrativeFileAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    // const offset = moment().utcOffset();
    const initDay = getTimestampAtStartOfDay();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset,
      zoneId: zoneId,
      ppcProcedureId: ppcProcedureId,
      returned: returned
    };

    const options = createRequestOption(req);

    return this.http
      .post<IPpcAdministrativeFileAdded>(
        `${baseUrl}/ppcadministrativefile/savePpcReopenOrReformulationAndValidate`,
        amendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdministrativeFileAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          if (returned) {
            console.error(
              'Se ha producido un error al guardar y validar la reapertura CPP en el servidor:',
              err.message
            );
          } else {
            console.error(
              'Se ha producido un error al guardar y validar la reformulación CPP en el servidor:',
              err.message
            );
          }
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReformulatedData(
    ppcProcedureId: number
  ): Observable<IPpcCorrectionOSData> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionOSData>(
        `${baseUrl}/ppcadministrativefile/ppcReformulatedData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionOSData>) =>
            resp.body as IPpcCorrectionOSData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos para reformular el expediente CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcTopicTypes(): Observable<IPpcTopicType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcTopicType[]>(
        `${baseUrl}/ppcadministrativefile/getPpcTopicTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcTopicType[]>) => resp.body as IPpcTopicType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las materias para el filtro de expediente CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcAreaTypes(): Observable<IPpcAreaType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcAreaType[]>(`${baseUrl}/ppcadministrativefile/getPpcAreaTypes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcAreaType[]>) => resp.body as IPpcAreaType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los ámbitos para el filtro de expediente CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
