import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { OmissionAfResponseReport } from '@ic-app/models/omission/correction-response/omission-af-correction-response.model';
import {
  IOmissionAfCorrectionContacts,
  IOmissionAfCorrectionDataDTO,
  IOmissionAfCorrectionDataEditDTO,
  OmissionAfCorrectionRequest
} from '@ic-app/models/omission/panel-correction/omission-af-correction-data.model';
import { getUserTimeZone } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampAtStartOfDay,
  getTimestampAtStartOfDayFromTimestamp
} from '@ic-core/util/request-util';
import { IOmissionDetailData } from '@ic-models/omission/omission-detail.model';
import {
  IOmissionAdded,
  IOmissionRequest
} from '@ic-models/omission/omission-request.model';
import {
  IPreviousFileType,
  IPreviousFileTypeCompatibility
} from '@ic-models/omission/previous-file-type.model';
import { IRadioOption } from '@ic-models/radio-button-option.model';
import { ISelectOption } from '@ic-models/select-option.model';
import { environment } from 'environments/environment';
// import * as _moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class OmissionService {
  constructor(private http: HttpClient) {}

  deleteOmission(administrativeFileId: number): Observable<boolean> {
    const req = {
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/inspectionomission/delete`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de Fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveOmission(omissionRequest: IOmissionRequest): Observable<IOmissionAdded> {
    const req = {
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .post<IOmissionAdded>(`${baseUrl}/inspectionomission`, omissionRequest, {
        params: options
      })
      .pipe(
        map((resp: IOmissionAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la solicitud de Fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionReasons(): Observable<ISelectOption[]> {
    return this.http
      .get<ISelectOption[]>(`${baseUrl}/inspectionomission/reasons`, {
        headers: HttpConstants.GET_HEADERS,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ISelectOption[]>) => resp.body as ISelectOption[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los motivos de omisión:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPreviousTypesByTypeId(
    administrativeFileTypeId: number
  ): Observable<IPreviousFileType[]> {
    const req = {
      administrativeFileTypeId: administrativeFileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPreviousFileType[]>(`${baseUrl}/inspectionomission/previousTypes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPreviousFileType[]>) =>
            resp.body as IPreviousFileType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente previos:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPreviousTypesCompatibilityByTypeId(
    previousFilesModelIds: number[]
  ): Observable<IPreviousFileTypeCompatibility[]> {
    const req = {
      previousFilesModelIds: previousFilesModelIds
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPreviousFileTypeCompatibility[]>(
        `${baseUrl}/inspectionomission/previousTypesCompatibility`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPreviousFileTypeCompatibility[]>) =>
            resp.body as IPreviousFileTypeCompatibility[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente previos compatibles:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateOmission(
    omissionRequest: IOmissionRequest,
    fileId: number,
    deletePronouncements: boolean,
    deletePrevious: boolean
  ): Observable<IOmissionAdded> {
    const req = {
      fileId: fileId,
      zoneId: getUserTimeZone(),
      deletePronouncements: deletePronouncements,
      deletePrevious: deletePrevious
    };
    const options = createRequestOption(req);
    return this.http
      .put<IOmissionAdded>(
        `${baseUrl}/inspectionomission/update`,
        omissionRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IOmissionAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Obtiene los datos del expediente para una omisión en determinada iteración
  findOmissionDetail(
    fileId: number,
    iteration: number
  ): Observable<IOmissionDetailData> {
    const req = {
      id: fileId,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/detail', {
        .get<IOmissionDetailData>(`${baseUrl}/inspectionomission/fileDetail`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map(
            (resp: HttpResponse<IOmissionDetailData>) =>
              resp.body as IOmissionDetailData
          ),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al cargar un expediente del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  getOmissionConclusion(): Observable<IRadioOption[]> {
    return this.http
      .get<IRadioOption[]>(`${baseUrl}/inspectionomission/conclusions`, {
        headers: HttpConstants.GET_HEADERS,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IRadioOption[]>) => resp.body as IRadioOption[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las conclusiones de omisión:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los datos para la informacion complementaria
   * @param fileId
   * @returns
   */
  getCorrectionData(fileId: number): Observable<IOmissionAfCorrectionDataDTO> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOmissionAfCorrectionDataDTO>(
        `${baseUrl}/inspectionomission/correctionData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IOmissionAfCorrectionDataDTO>) =>
            resp.body as IOmissionAfCorrectionDataDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos para la información complementaria',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los contactos para la solicitud de informacion complementaria
   * @param organizationalUnitId
   * @returns
   */
  getCorrectionContacts(
    organizationalUnitId: number
  ): Observable<IOmissionAfCorrectionContacts[]> {
    const req = {
      organizationalUnitId: organizationalUnitId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOmissionAfCorrectionContacts[]>(
        `${baseUrl}/inspectionomission/organizationalUnitContacts`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IOmissionAfCorrectionContacts[]>) =>
            resp.body as IOmissionAfCorrectionContacts[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los contactos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda la solicitud de información complementaria
   * @param correctionData
   * @returns
   */
  saveCorrection(
    correctionData: OmissionAfCorrectionRequest
  ): Observable<number> {
    const initDay = getTimestampAtStartOfDay();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/inspectionomission/saveCorrection`,
        correctionData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la solicitud de información complementaria en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de solicitud de información a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithOmissionCorrectionReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/inspectionomission/sendEmail/correctionReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda una solicitud de información complementaria y la envia
   * @param correctionData
   * @param send
   * @returns
   */
  saveCorrectionAndSend(
    correctionData: OmissionAfCorrectionRequest,
    send: boolean = true
  ): Observable<number> {
    const req = {
      initDay: getTimestampAtStartOfDay(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionomission/saveOmissionAfCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la solicitud de información complementaria en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los datos de la solicitud de info complementaria a editar
   * @param fileId
   * @returns
   */
  getCorrectionToEdit(
    fileId: number
  ): Observable<IOmissionAfCorrectionDataEditDTO> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOmissionAfCorrectionDataEditDTO>(
        `${baseUrl}/inspectionomission/correctionDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IOmissionAfCorrectionDataEditDTO>) =>
            resp.body as IOmissionAfCorrectionDataEditDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la información de la solicitud de información complementaria a editar del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Modifica una solicitud de información complementaria
   * @param correctionData
   * @param officialNoteId
   * @returns
   */
  editCorrection(
    correctionData: OmissionAfCorrectionRequest,
    officialNoteId: number
  ): Observable<number> {
    const req = { officialNoteId: officialNoteId };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionomission/updateOmissionAfCorrection`,
        correctionData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la solicitud de información compelmentaria en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Modifica una solicitud de información complementaria y la oficia o envía
   * @param correctionData
   * @param officialNoteId
   * @param send
   * @returns
   */
  editCorrectionAndSend(
    correctionData: OmissionAfCorrectionRequest,
    officialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId,
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/inspectionomission/editOmissionAfCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la solicitud de información complementaria en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Elimina una solicitud de información complementaria
   * @param fileId
   * @returns
   */
  deleteOmissionAfCorrectionRequest(fileId: number): Observable<boolean> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/inspectionomission/deleteCorrectionRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de información complementaria del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Busca los datos del expediente actual
   * @param fileId
   * @returns
   */
  findCurrentOmissionAdministrativeFile(
    fileId: number
  ): Observable<IOmissionDetailData> {
    const req = {
      id: fileId
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/detail', {
        .get<IOmissionDetailData>(`${baseUrl}/inspectionomission/findCurrent`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map(
            (resp: HttpResponse<IOmissionDetailData>) =>
              resp.body as IOmissionDetailData
          ),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al cargar el expediente de omisión del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  /**
   * Guarda la respuesta al oficio de solicitud de información complementaria
   * @param omissionResponseReport
   * @param administrativeFileId
   * @returns
   */
  saveOmissionResponseReport(
    omissionResponseReport: OmissionAfResponseReport,
    administrativeFileId: number
  ): Observable<boolean> {
    const zoneId = getUserTimeZone();
    const initDay = getTimestampAtStartOfDayFromTimestamp(
      omissionResponseReport.responseDate
    );
    const req = {
      zoneId: zoneId,
      initDay: initDay
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/inspectionomission/omissionResponseReport/${administrativeFileId}/`,
        omissionResponseReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.log(
            `Error al responder el oficio del expediente ${administrativeFileId}`,
            err
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
